/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

const Offer15 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "karisma.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.karisma}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Biostymulator na bazie Rh kolagenu
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="karisma biostymulator na bazie Rh kolagenu terapia zastępcza ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Wskazania do zabiegu</Subtitle>
            <Paragraph>
              Skóra, która traci sprężystość, wymaga rewitalizacji, uwidoczniają
              się na niej oznaki starzenia, występują na niej blizny zanikowe i
              potrądzikowe. Preparat medyczny w naturalny sposób odmładza,
              rewitalizuje i napina skórę, wypełnia drobne zmarszczki oraz daje
              lekki efekt liftingu. Dla pełnego efektu wystarczą dwa zabiegi.
              Skóra po zabiegu jest napięta, pełna naturalnego blasku,
              wygładzone są zmarszczki i blizny. Można zaobserwować również
              delikatny efekt liftingu, wypełnienia.
            </Paragraph>
            <Paragraph noMarginBottom>
              W skład tego biostymulatora wchodzą:
            </Paragraph>
            <List>
              <ListItem>
                Polipeptydowy łańcuch α1 kolagenu typu I Kolagen zawarty w
                preparacie preparacie jest uzyskiwany z zastosowaniem
                innowacyjnej technologii z wykorzystaniem nici jedwabnych. W
                odróżnieniu od innych produktów zastosowany kolagen, mimo pełnej
                zgodności tkankowej z ludzkim kolagenem, nie jest pochodzenia
                zwierzęcego. Dzięki technologii NEOSILK, zwiększa się produkcja
                peptydu C prokolagenu typu I w fibroblastach. Ten typ peptydów
                ułatwia owijanie cząsteczek prokolagenu w konformację potrójnej
                helisy (superhelisy) w retikulum endoplazmatycznym.
              </ListItem>
              <ListItem>
                CMC – karboksymetyloceluloza. Działa przeciwstarzeniowo,
                ogranicza aktywność wolnych rodników tlenowych i endogennej
                hialuronidazy. Ma działanie ochronne w stosunku do kwasu
                hialuronowego.
              </ListItem>
              <ListItem>
                HA – kwas hialuronowy o wysokiej masie cząsteczkowej (1200-1500
                kDa). Daje natychmiastowy efekt pozabiegowy i poprawia jakość
                macierzy zewnątrzkomórkowej, sprzyja migracji fibroblastów.
              </ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu</Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej. W zależności od wskazań, preparat podawany
              jest przy użyciu igły lub kaniuli. Procedurę wykonuje się w
              miejscu wcześniej znieczulonym przy pomocy specjalnego kremu.
              Zabieg nie wymaga rekonwalescencji.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Po jakim czasie i jak długo widoczne są efekty po zabiegu?
            </Subtitle>
            <Paragraph>
              Protokół zakłada wykonanie 2 zabiegów w odstępie 1 miesiąca. W
              zależności od wieku, stylu życia i jakości skóry, efekty zabiegu
              utrzymują się od 6 do 12 miesięcy. Warto wykonać jeden zabieg
              przypominający po 4 miesiącach dla podtrzymania efektu. Zdjęcia
              ukazują efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może
              się różnić w zależności od indywidualnych cech pacjenta, liczby
              powtórzeń zabiegu, stosowania się pacjenta do zaleceń
              pozabiegowych oraz umiejętności i doświadczenia osoby
              przeprowadzającej zabieg.
            </Paragraph>
            <SImageWrapper>
              <Image
                src={images.first.childImageSharp}
                alt="karisma przed po"
              />
            </SImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena zabiegu</Subtitle>
            <Paragraph>
              Cena zabiegu zaczyna się od 1 300 zł. Przy pierwszej wizycie
              odbierz swoją kartę lojalnościową i otrzymaj -30% na czwarty
              zabieg medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania do zabiegu</Subtitle>
            <List>
              <ListItem>Aktywne zakażenia okolicy zabiegu</ListItem>
              <ListItem>Cukrzyca</ListItem>
              <ListItem>Choroby autoimmunologiczne</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Tendencje do powstawania bliznowców</ListItem>
              <ListItem>Ciąża, okres karmienia piersią</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer15;

export const Head = () => <SEO tags={TAGS.karisma} />;
